// ***************************************************************  All Power BI Dashboards  ***************************************************************

export const dashboards = {
  // Case Analyzer Tool
  caseAnalyzerTool: {
    scoreDistribution: {
      refresh2024: {
        reportId: "8399a183-b04b-4560-80d9-0cc242d9f310",
      },
      refresh2023: {
        reportId: "be1c54a5-afeb-4efb-9cbf-da28e1c47dc0",
      },
      refresh2022: {
        reportId: "9cf615fd-54a5-4a33-82a5-74d6af1469c4",
      },
    },
    attributePropensity: {
      refresh2024: {
        reportId: "9da2bb7c-9662-4b16-972e-cb1e3ede9571",
      },
      refresh2023: {
        reportId: "335f77c0-9ac3-4af7-8323-e192954cabc6",
      },
      refresh2022: {
        reportId: "b81f0829-cc8d-4734-9c66-3f55a2277a78",
      },
    },
    territoryDesign: {
      refresh2022: {
        reportId: "9ecdc534-75e9-4468-8ef5-799f09f35b43",
      },
    },
    brokerDashboard: {
      refresh2022: {
        reportId: "f3a5464c-6d7a-43f8-9cff-d6e33a020a24",
      },
    },
    catEconomicAnalysis: {
      timelineAgnostic: {
        reportId: "1a4e4f53-5ec7-4a8b-a872-40718cec8a5c",
      },
    },
  },

  // Renewals Analyzer Tool
  renewalAnalyzerTool: {
    renewalsSummary: {
      q1_2024_refresh: {
        reportId: "bd780e1f-af86-402d-bdcd-e9eae4f55c97",
      },
      q3_2023_refresh: {
        reportId: "815f9fed-1054-4244-981d-f580641ec319",
      },
      q1_2023_deployment: {
        reportId: "8986e57c-1b5a-45de-8468-a1126eb00ba8",
      },
    },
    attributeAnalysis: {
      q1_2024_refresh: {
        reportId: "c014c542-8926-45b5-a8df-76225075d480",
      },
      q3_2023_refresh: {
        reportId: "b6b3722e-e3c5-4f11-a6c8-7990b962f8e6",
      },
      q1_2023_deployment: {
        reportId: "c52bd2a5-3bc2-45e0-bc00-cfc1cea31f67",
      },
    },
    targetSponsorList: {
      q3_2023_refresh: {
        reportId: "09e6542e-9e87-4171-b6b2-f8ffb9d4104a",
      },
      q1_2023_deployment: {
        reportId: "920628bc-cb8f-4eda-a160-1ba41eb27783",
      },
    },
    targetSponsorListCoreSmallBusiness: {
      q3_2024_refresh: {
        reportId: "2161d829-89e1-40c6-bba3-9ea9f142aada",
      },
      q1_2024_refresh: {
        reportId: "86f2b2b9-df6d-4fac-a361-faa00d6e1838",
      },
    },
    targetSponsorListNationalAccounts: {
      q1_2024_refresh: {
        reportId: "c12f788c-c9e0-4eb3-880e-d6858c914127",
      },
    },
    activeSponsorDetails: {
      q1_2024_refresh: {
        reportId: "7257a5c2-2d73-46fd-8e02-b5b8c1b8b5ac",
      },
      q3_2023_refresh: {
        reportId: "6b8b2dbd-a2dc-4a1d-87d8-41ecd7d88400",
      },
      q1_2023_deployment: {
        reportId: "8ba10e36-5dbf-45e4-a4b7-42bac03d466d",
      },
    },
  },

  //  Broker Analyzer Tool
  brokerAnalyzerTool: {
    brokerFactbase: {
      q1_2024_refresh: {
        reportId: "b3840cf2-01e4-467b-a8db-0267ed136b18",
        note: "The scorecard below reflects current active inforce sponsors as of Q1 2024",
      },
      q3_2023_deployment: {
        reportId: "98a5242d-95a9-4637-b5f6-55a6346c6257",
        note: "The scorecard below reflects current active inforce sponsors as of Q3 2023",
      },
    },
    gaFactabase: {
      q1_2024_refresh: {
        reportId: "1b2dd16a-428b-4ccb-820a-3c2b11d7219e",
        note: "The scorecard below reflects current active inforce sponsors as of Q1 2024",
      },
    },
    brokerScorecard: {
      q1_2024_refresh: {
        reportId: "d005c2d5-623d-4247-b179-f907065f420b",
        note: "The scorecard below reflects current active inforce sponsors as of Q1 2024",
      },
      q3_2023_deployment: {
        reportId: "007da9c3-c974-4ee1-84c1-c5b6c85bca01",
        note: "The scorecard below reflects current active inforce sponsors as of Q3 2023",
      },
    },
    topBrokerComparison: {
      q1_2024_refresh: {
        reportId: "e216b8ce-baa2-4bd0-94f8-9d177252aac6",
        note: "The scorecard below reflects current active inforce sponsors as of Q1 2024",
      },
      q3_2023_deployment: {
        reportId: "d3987e2c-7104-4672-b28b-844a097acd3b",
        note: "The scorecard below reflects current active inforce sponsors as of Q3 2023",
      },
    },
  },

  // Cross-sell Dashboard
  crossSellDashboard: {
    productRecommendationEngine: {
      timelineAgnostic: {
        reportId: "b8f11327-2d6c-4e8f-983a-4df9c43ea713",
      },
    },
    crossSellAnalysis: {
      timelineAgnostic: {
        reportId: "fa8c446e-ffe6-4c50-b668-6482bca04e24",
      },
    },
  },

  // STF Analysis
  stfAnalysis: {
    timelineAgnostic: {
      reportId: "651e9a8b-8460-4e2a-89fb-234b6c9b1fbf",
    },
  },
};
