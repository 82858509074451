import React, { useState, useContext, useEffect } from "react";
import Breadcrumbs from "../../components/account/Breadcrumbs";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import {
  breadCrumbs,
  caseAnalyzerTool,
  refresh2023,
  refresh2024,
} from "../../data/data";
import FilterDeployment from "../../components/modules/FilterDeployment";

const spinnakerLogo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-logo.webp";

const CaseAnalyzerTool = () => {
  const { setPageTitle } = useContext(UserContext);
  const [deployment, setDeployment] = useState(
    localStorage.getItem("cat-deployment-title") &&
      localStorage.getItem("cat-deployment-value")
      ? {
          title: localStorage.getItem("cat-deployment-title"),
          slug: localStorage.getItem("cat-deployment-value"),
        }
      : {
          title: caseAnalyzerTool.deployments[0].title,
          slug: caseAnalyzerTool.deployments[0].slug.split("/")[1],
        }
  );

  useEffect(() => {
    setPageTitle(caseAnalyzerTool.title + ": " + deployment.title);
  }, [deployment]);

  return (
    <section className="p-5 pt-0 w-full min-h-[35rem]">
      <div className="flex flex-col md:flex-row justify-between">
        <Breadcrumbs crumbs={breadCrumbs(caseAnalyzerTool)} />
        <FilterDeployment
          deployments={caseAnalyzerTool.deployments}
          setDeployment={setDeployment}
          selected={deployment}
          prefix={"cat-"}
        />
      </div>
      <div className="w-full my-10 pb-10 relative flex flex-col gap-20 transition-all duration-300 ease-in-out">
        <div className="md:px-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 h-full gap-5 transition-all duration-300 ease-in-out">
          {deployment?.title === refresh2023.title &&
            caseAnalyzerTool.refresh2023?.map((module, index) => (
              <div
                key={index}
                className="relative grid p-2 h-full rounded-lg gap-1 items-center text-xs  transition-all duration-300 ease-in-out"
              >
                <Link
                  to={caseAnalyzerTool.slug + refresh2023.slug + module.slug}
                  className={`w-full h-full p-10 flex flex-col gap-2 relative border bg-primary bg-opacity-5 rounded-2xl shadow-lg ${
                    module.slug === "#"
                      ? "cursor-default"
                      : "cursor-pointer hover:text-white  border-tertiary hover:border-primary hover:bg-opacity-100"
                  }`}
                >
                  <span className="absolute text-4xl top-2 right-3 opacity-10">
                    {caseAnalyzerTool.icon}
                  </span>
                  <div className="flex gap-2 items-center">
                    <span className="p-1 rounded-full text-2xl w-fit text-tertiary">
                      {module.icon}
                    </span>
                    <h3 className="font-semibold text-sm">{module.title}</h3>
                  </div>
                  <p className="">{module.description}</p>
                </Link>
              </div>
            ))}
          {deployment?.title === refresh2024.title &&
            caseAnalyzerTool.refresh2024?.map((module, index) => (
              <div
                key={index}
                className="relative grid p-2 h-full rounded-lg gap-1 items-center text-xs  transition-all duration-300 ease-in-out"
              >
                <Link
                  to={caseAnalyzerTool.slug + refresh2024.slug + module.slug}
                  className={`w-full h-full p-10 flex flex-col gap-2 relative border bg-primary bg-opacity-5 rounded-2xl shadow-lg ${
                    module.slug === "#"
                      ? "cursor-default border-tertiary"
                      : "cursor-pointer hover:text-white hover:border-primary hover:bg-opacity-100"
                  }`}
                >
                  <span className="absolute text-4xl top-2 right-3 opacity-10">
                    {caseAnalyzerTool.icon}
                  </span>
                  <div className="flex gap-2 items-center">
                    <span className="p-1 rounded-full text-2xl w-fit text-tertiary">
                      {module.icon}
                    </span>
                    <h3 className="font-semibold text-sm">{module.title}</h3>
                  </div>
                  <p className="">{module.description}</p>
                </Link>
              </div>
            ))}
        </div>
      </div>
      {/* Powered By Spinnaker */}
      <div className="fixed hidden md:flex flex-col gap-10 right-5 bottom-5">
        <div className="w-full flex flex-col items-end scale-75 xl:scale-90">
          <p className="text-[10px] 2xl:text-xs">Powered by</p>
          <img
            src={spinnakerLogo}
            alt="powered by spinnaker analytics"
            className="w-28"
          />
        </div>
      </div>
    </section>
  );
};

export default CaseAnalyzerTool;
